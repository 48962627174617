<template>
  <div>
    <h4>Уведомления</h4>
    <p>Включите уведомления о новинках и акциях</p>
    <router-link to="/profile/notification">Перейти</router-link>
    <img class="img-cabinet" src="@/assets/img/music-and-multimedia.svg" />
  </div>
</template>

<script>
export default {
  name: "ProfileNotifications"
};
</script>

<style scoped></style>
