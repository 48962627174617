<template>
  <div class="b-kabinet-top" v-if="user">
    <div class="name">
      <div>
        <span>{{ user.name }}</span>
        <a>{{ user.email }}</a>
      </div>
      <span
        class="blue"
        @click="$router.push({ name: 'AdminVideo' })"
        v-if="isAdmin"
      >
        <i class="fas fa-user-shield"></i>
      </span>
    </div>
    <div class="mail">
      <a href="#" @click.prevent="logout">Выйти</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileTop",
  computed: {
    user() {
      return this.$auth.user();
    },
    isAdmin() {
      return this.$auth.check("admin");
    }
  },
  methods: {
    logout() {
      this.$auth
        .logout({
          makeRequest: true,
          redirect: { name: "login" }
        })
        .catch(() => {
          this.$auth.logout({
            makeRequest: false,
            redirect: { name: "login" }
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
span.blue {
  background: #3435e6;
  border-radius: 4px;
  padding: 4px 18px;
  letter-spacing: 0;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;

  @media screen and (max-width: 361px) {
    margin-right: 20px;
    margin-left: 0;
  }

  &:hover {
    background: #4849ea;
  }
}
</style>
