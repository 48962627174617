<template>
  <div>
    <h4>Смотреть позже</h4>
    <p>Видео, которые вы сохранили</p>
    <router-link to="/profile/favourite">Перейти</router-link>
    <img class="img-cabinet" src="@/assets/img/bookmark1.svg" />
  </div>
</template>

<script>
export default {
  name: "ProfileWatchLater"
};
</script>

<style scoped></style>
