<template>
  <div>
    <h4>История просмотров</h4>
    <p>Вернитесь к просмотру в любой момент</p>
    <router-link to="/profile/viewed">Перейти</router-link>
    <img class="img-cabinet img-eye" src="@/assets/img/eye.svg" />
  </div>
</template>

<script>
export default {
  name: "ProfileHistory"
};
</script>

<style scoped></style>
