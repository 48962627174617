<template>
  <div v-if="user">
    <h4>Подписка</h4>
    <p v-if="user.isSubscribe && user.subType === 'SUB'">
      Включить автопродление
      <switches
        v-model="isAutoPayment"
        theme="custom"
        color="blue"
        type-bold="true"
        :emit-on-mount="false"
        :disabled="!isAutoPayment"
      />
    </p>
    <router-link to="/sub">ВСЕ ПОДПИСКИ</router-link>
    <img class="img-cabinet" src="@/assets/img/video-player.svg" alt="player" />
    <template v-if="user.isSubscribe">
      <span class="rad" v-if="subscriptionExpired">
        Закончилась {{ subscriptionDate | moment("calendar") }}
      </span>
      <span class="blue" v-else>
        Активна до {{ subscriptionDate | moment("calendar") }}
      </span>
    </template>
    <template v-else>
      <span class="gray">Подписка не подключена</span>
    </template>
  </div>
</template>

<script>
import Switches from "vue-switches";
export default {
  name: "ProfileSub",
  components: { Switches },
  computed: {
    isAutoPayment: {
      get() {
        return this.user ? this.user.renew : false;
      },
      set(n) {
        this.$auth.user({ renew: n });
      }
    },
    user() {
      return this.$auth.user() || false;
    },
    subscriptionDate() {
      return new Date(
        this.user && this.user.isSubscribe ? this.user.subscriptionDate : 0
      ).getTime();
    },
    subscriptionExpired() {
      return this.subscriptionDate < this.nowDate;
    },
    nowDate() {
      return new Date().getTime();
    }
  },
  methods: {
    cancelSubscription() {
      this.axios.get("/payments/sub/cancel").then(response => {
        const flag = response.data;
        if (flag) {
          this.$auth.fetchUser();
        }
      });
    }
  },
  watch: {
    isAutoPayment(n) {
      if (!n) {
        this.cancelSubscription();
      }
    }
  }
};
</script>

<style lang="scss">
.vue-switcher {
  margin-left: 23px;
  margin-top: 5px;
  &.vue-switcher-theme--custom {
    &.vue-switcher-color--blue {
      div {
        background-color: #2b2b2b;

        &:after {
          // for the circle on the switch
          background-color: #3436ea;
        }
      }
      &.vue-switcher--bold {
        & div {
          padding: 13px;
        }
      }
      &.vue-switcher--unchecked {
        div {
          background-color: #353535;

          &:after {
            background-color: lighten(blue, 10%);
          }
        }
      }
    }
  }
}
</style>
