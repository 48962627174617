<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <profile-top />
      <div class="flex-kabinet-tho">
        <profile-personal />
        <profile-sub />
      </div>
      <div class="flex-kabinet-three">
        <profile-history />
        <profile-watch-later />
        <profile-notifications />
      </div>
      <div class="flex-kabinet-tho">
        <profile-cert />
        <profile-promo />
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import ProfileTop from "@/components/page/profile/ProfileTop";
import ProfileSub from "@/components/page/profile/ProfileSub";
import ProfilePersonal from "@/components/page/profile/ProfilePersonal";
import ProfileHistory from "@/components/page/profile/ProfileHistory";
import ProfileWatchLater from "@/components/page/profile/ProfileWatchLater";
import ProfileNotifications from "@/components/page/profile/ProfileNotifications";
import ProfileCert from "@/components/page/profile/ProfileCert";
import ProfilePromo from "@/components/page/profile/ProfilePromo";
export default {
  name: "Profile",
  components: {
    ProfilePromo,
    ProfileCert,
    ProfileNotifications,
    ProfileWatchLater,
    ProfileHistory,
    ProfilePersonal,
    ProfileTop,
    ProfileSub
  }
};
</script>

<style scoped></style>
