<template>
  <div>
    <h4>Личные данные</h4>
    <p>Имя, телефон, e-mail</p>
    <router-link to="/profile/data">Редактировать</router-link>
    <img class="img-cabinet" src="@/assets/img/user.svg" alt="user" />
  </div>
</template>

<script>
export default {
  name: "ProfilePersonal"
};
</script>

<style scoped></style>
