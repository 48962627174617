<template>
  <div>
    <h4>Промокод</h4>
    <p>Введите промокод</p>
    <router-link to="/promo">Ввести</router-link>
    <img class="img-cabinet" src="@/assets/img/communications.svg" />
  </div>
</template>

<script>
export default {
  name: "ProfilePromo"
};
</script>

<style scoped></style>
