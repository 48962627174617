<template>
  <div>
    <h4>Сертификат</h4>
    <p>Подарите подписку близким</p>
    <router-link to="/certs">Подарить</router-link>
    <img class="img-cabinet" src="@/assets/img/interface.svg" />
  </div>
</template>

<script>
export default {
  name: "ProfileCert"
};
</script>

<style scoped></style>
